import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ContentTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentDetailGlobal, contentUserDetail, contentUserList, contentUserMyList } from '../../../services/api';
import { generateDynamicColor } from '../../../styles/color';
import theme from '../../../styles/theme';
import { groupByList } from '../../../utilies/expression';
import { BiaxialBarChart, CustomActiveShapePieChart, LineChart, Select } from '../../globals';
import BiaxialBarChartDetail from '../../globals/tool/BiaxialBarChartDetail';

export default function ContentPreview({ parameter, id, period, onWidthChange }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [listData, setListData] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [lineGroup, setLineGroup] = useState([]);
  const [chartDetailData, setChartDetailData] = useState(null);
  const [design, setDesign] = useState(3);

  const [contentFilter] = useState({
    pageNumber: 1,
    dataLimit: 1,
    country: user.c,
    userCountry: user.c,
    includeChildren: true,
    includeGroups: true,
    orderBy: ['modificationTime|desc'],
  });

  const [contentUserFilter] = useState({
    includeSelections: true,
    userId: parameter.id,
    orderBy: ['modificationTime|desc'],
  });

  // Hooks
  useEffect(() => {
    load();
  }, [id, period]);

  // Functions
  const load = async () => {
    let [contentDetailResponse, contentUserListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        contentDetailGlobal({ ...contentFilter, id }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        contentUserDetail({ ...contentUserFilter, parentContentId: id, period }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (!contentDetailResponse) {
      setResponse(null);
      return;
    }

    let result = contentDetailResponse.data;
    result.typeData = ContentTypes.find((x) => x.value === result.type);

    if (contentUserListResponse?.data?.selections?.length > 0) {
      result.userSelection = contentUserListResponse.data;

      if (result.children?.length > 0) {
        result.contentGroups = [];
        result.children = result.children.map((question) => {
          if (question.children?.length > 0) {
            question.children = question.children.map((answer) => {
              if (contentUserListResponse.data.selections.includes(answer.id)) {
                answer.checked = true;
                question.point = answer.point;
              }

              return answer;
            });

            if (question.groups?.length > 0) {
              result.contentGroups = [...result.contentGroups, ...question.groups.map((x) => ({ ...x, point: question.point }))];
            }
          }

          return question;
        });

        if (result.contentGroups.length > 0) {
          result.contentGroupResults = groupByList(result.contentGroups, 'title').map((x) => {
            x.point = x.values.length > 1 ? x.values.map((x) => x.point).reduce((a, b) => a + b, 0) : x.values[0].point;
            x.color = generateDynamicColor();
            return x;
          });
        }
      }
    }

    setResponse(result);
  };

  const loadPeriodicSelections = async () => {
    let request = parameter.isMyData ? contentUserMyList : contentUserList;

    let listFilter = {
      userId: parameter.id,
      isMyData: parameter.isMyData,
      includeParentContent: true,
      includeSelections: true,
      country: parseInt(user.c),
      userRole: parameter.userRole,
      parentContentId: id,
      dataLimit: 4,
      orderBy: ['modificationTime|desc'],
    };

    await request(listFilter, (status, res) => {
      if (res.data?.length > 0 && response.children?.length > 0) {
        let data = [];
        let result = [];
        let contentGroups = [];

        // Combine Question & Answer
        res.data.forEach((item) => {
          let periodResult = { ...item, groups: [] };
          response.children.forEach((question) => {
            if (question?.children?.length > 0 && question?.groups?.length > 0) {
              question.children.forEach((answer) => {
                // Correct Answer
                if (item.selections.includes(answer.id)) {
                  periodResult.groups = [...periodResult.groups, ...question.groups.map((x) => ({ ...x, point: answer.point }))];
                  contentGroups = [...contentGroups, ...periodResult.groups];
                }
              });
            }
          });
          result.push(periodResult);
        });

        // Prepare Chart Data
        let dataGroup = [];
        let lineDataList = [];
        contentGroups = groupByList(contentGroups, 'title');
        contentGroups.forEach((item, index) => {
          let firstItem = { label: item.key, items: [] };
          let dataItem = { name: item.key };
          let groupSelections = result.filter((x) => x.groups.some((s) => s.title === item.key));
          groupSelections.forEach((selection) => {
            let currentGroup = selection.groups.filter((x) => x.title === item.key);
            let groupPoints = currentGroup?.length > 1 ? currentGroup.map((x) => x.point).reduce((a, b) => a + b, 0) : currentGroup[0].point;

            let selectionStartTime = moment(selection.creationTime).format('MMM YYYY');
            let selectionEndTime = moment(selection.modificationTime).format('MMM YYYY');
            let name = selectionStartTime !== selectionEndTime ? `${selectionStartTime} ~ ${selectionEndTime}` : selectionStartTime;

            if (lineDataList.find((x) => x.name === name)) {
              lineDataList = lineDataList.map((x) => {
                if (x.name === name) {
                  return { ...x, [item.key]: groupPoints };
                }

                return x;
              });
            } else {
              lineDataList.push({ name: name, [item.key]: groupPoints });
            }

            if (!dataGroup.some((x) => x === name)) dataGroup = [...dataGroup, name];

            firstItem.items.push({ dataKey: name, value: groupPoints });
            dataItem[name] = groupPoints;
          });

          if (index === 0) {
            setChartDetailData(firstItem);
          }

          let contentGroupResult = response.contentGroupResults?.find((x) => x.key === item.key);
          if (contentGroupResult) {
            dataItem['color'] = contentGroupResult.color;
          }

          data.push(dataItem);
        });
        setLineGroup(data.map((x) => ({ name: x.name, color: generateDynamicColor() })));
        setLineData(lineDataList);

        // Define Colors
        if (dataGroup.length > 1) {
          let lastGroup = dataGroup[dataGroup.length - 1]; // Period 172
          let previousGroup = dataGroup[dataGroup.length - 2]; // Period 1

          data = data.map((x) => {
            x.color = x[lastGroup] < x[previousGroup] ? theme.colors.robinEggBlue : theme.colors.rustyRed;
            if (!x[previousGroup]) x.color = theme.colors.lavenderGray;

            if (x[lastGroup] === x[previousGroup]) x.color = theme.colors.score1;
            return x;
          });
        }

        setListGroup(dataGroup);
        setListData(data);
      }
    });
  };

  // Events
  const onDesignChange = (value) => {
    setDesign(value);
    if (onWidthChange) {
      onWidthChange(value);
    }

    if (value === 4 || value === 5 || value === 6) {
      loadPeriodicSelections();
    }
  };

  // Components
  const onUpdateTooltipContent = ({ label, items }) => {
    setChartDetailData({ label, items });
  };

  // Render
  return response ? (
    <Container>
      <Header>
        <Left>
          <Title>{response.title}</Title>
          <ScoreText>
            {t('TotalPoint')}: <strong>{response.userSelection.totalPoint}</strong>
          </ScoreText>
        </Left>
        <Right>
          <Select
            key="design-filter"
            data={[
              {
                text: t('PreviewFullDetailDesign'),
                value: 0,
              },
              response.contentGroupResults && {
                text: t('PreviewQuestionAnswerSingleGroupDesign'),
                value: 3,
              },
              response.contentGroupResults && {
                text: t('PreviewQuestionAnswerPeriodicGroupDesign'),
                value: 4,
              },
            ]}
            allowClear={false}
            onChange={onDesignChange}
            placeholder={t('SelectDesign')}
            value={design}
            templates={['filter', 'width-300']}
          />
        </Right>
      </Header>

      {response.legendText && <LegendTextBody dangerouslySetInnerHTML={{ __html: response.legendText }} />}

      <PreviewDetail type={design}>
        {response.children && design < 3 && (
          <Items>
            {response.children.map((question, qIndex) => (
              <Item key={`question-${qIndex}`}>
                <Question>
                  <QuestionTitle>{question.title}</QuestionTitle>
                  {question.groups && (
                    <QuestionGroups>
                      {question.groups.map((group, groupIndex) => (
                        <QuestionGroupItem key={`group-${groupIndex}`}>#{group.title}</QuestionGroupItem>
                      ))}
                    </QuestionGroups>
                  )}

                  {design === 0 && <QuestionBody dangerouslySetInnerHTML={{ __html: question.body }} />}
                </Question>
                {question.children && (
                  <Answers>
                    {question.children.map((answer, aIndex) => (
                      <Answer key={`answer-${aIndex}`} checked={answer.checked} correct={answer.isCorrect}>
                        {design < 2 && <AnswerTitle>{answer.title}</AnswerTitle>}
                        {answer.point > 0 && (
                          <Point>
                            <PointTitle>{t('Point')}:</PointTitle>
                            <PointText>{answer.point}</PointText>
                          </Point>
                        )}
                      </Answer>
                    ))}
                  </Answers>
                )}
              </Item>
            ))}
          </Items>
        )}

        {(design === 3 || design === 5) && (
          <ContentGroupResultsContainer>
            {response.contentGroupResults && (
              <ContentGroupResults>
                {response.contentGroupResults.map((x, i) => (
                  <ContentGroupResultItem key={`item-${i}`}>
                    <ContentGroupResultTitle color={x.color}>#{x.key}</ContentGroupResultTitle>
                    <ContentGroupResultCount>{x.point}</ContentGroupResultCount>
                  </ContentGroupResultItem>
                ))}
              </ContentGroupResults>
            )}

            {response.contentGroupResults && (
              <CustomActiveShapePieChart data={response.contentGroupResults.map((x) => ({ name: x.key, value: x.point, color: x.color }))} />
            )}
          </ContentGroupResultsContainer>
        )}

        {(design === 4 || design === 5) && (
          <ContentGroupPeriodicResults>
            <BiaxialBarChartDetail data={chartDetailData} />

            <BiaxialBarChart data={listData} groups={listGroup} onUpdateTooltipContent={onUpdateTooltipContent} />
          </ContentGroupPeriodicResults>
        )}

        {design === 6 && (
          <ContentGroupLieanerResults>
            <BiaxialBarChartDetail data={chartDetailData} />
            <LineChart width={720} height={400} data={lineData} groups={lineGroup} xAxisDataKey="name" />
          </ContentGroupLieanerResults>
        )}
      </PreviewDetail>
    </Container>
  ) : (
    <Skeleton />
  );
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const ScoreText = styled.div``;

const PreviewDetail = styled.div`
  ${({ type }) => {
    switch (type) {
      case 5:
        return css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `;
      default:
        break;
    }
  }}
`;

const Items = styled.div``;

const Item = styled.div`
  margin-top: 24px;
`;

const Question = styled.div`
  margin-bottom: 8px;
`;

const QuestionTitle = styled.div`
  font-weight: bold;
`;

const QuestionGroups = styled.div`
  display: block;
  overflow: hidden;
`;

const QuestionGroupItem = styled.div`
  float: left;
  margin-right: 8px;
  font-style: italic;
  color: ${(x) => x.theme.colors.lavenderGray};
`;

const QuestionBody = styled.div``;

const Answers = styled.div`
  display: flex;
`;

const Answer = styled.div`
  margin-right: 12px;
  border: 2px solid ${(x) => x.theme.colors.darkTurquoise};
  padding: 5px 10px;
  border-radius: 10px;

  ${({ correct, checked }) =>
    checked && correct
      ? css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          color: ${(x) => x.theme.colors.white};
        `
      : checked &&
        css`
          border: 2px solid ${(x) => x.theme.colors.rustyRed};
          background-color: ${(x) => x.theme.colors.rustyRed};
          color: ${(x) => x.theme.colors.white};
        `}

  ${({ correct }) =>
    correct &&
    css`
      border: 2px solid ${(x) => x.theme.colors.mediumSpringGreen};
    `}
`;

const Point = styled.div`
  display: flex;
  opacity: 0.8;
`;

const PointTitle = styled.div``;

const PointText = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const AnswerTitle = styled.div``;

const ContentGroupResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentGroupResults = styled.div`
  margin-top: 24px;
`;

const ContentGroupResultItem = styled.div`
  margin-bottom: 12px;
`;

const ContentGroupResultTitle = styled.div`
  font-weight: bold;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const ContentGroupResultCount = styled.div``;

const ContentGroupPeriodicResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
`;

const ContentGroupLieanerResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
  position: relative;
`;

const LegendTextBody = styled.div`
  padding-top: 24px;
`;
